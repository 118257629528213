<!--
 * @Author: your name
 * @Date: 2020-10-20 14:14:00
 * @LastEditTime: 2020-10-21 09:47:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\RecoverPassword.vue
-->
<template>
  <div class="resetPassword">
    <div class="title mb-4">{{ $t("Set.dcd@reset_payment_passwo") }}</div>
    <!-- <form class="mt-4"> -->
    <div class="form-group">
      <label>{{ $t("Set.cf9@new_payment_password") }}</label>
      <input type="password" class="form-control mb-0" :placeholder="$t('Set.197@new_password')" v-model="form.password"
        maxlength="6" />
    </div>

    <div class="d-flex justify-content-end">
      <button @click="handleClickSubmit" type="submit" class="btn btn-primary float-right">
        {{ $t("Set.ca8@submit") }}
      </button>
      <!-- </router-link> -->
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
  export default {
    name: "RecoverPassword",
    data: () => ({
      form: {
        password: "",
        user_token: "",
        email:""
      },
    }),
    mounted() {
      // console.log(this.$route.query.token);
    },
    methods: {
      handleClickSubmit() {
        // console.log(window.location.href.split("token=")[1]);
        let params = window.location.href.split("token=")[1]
        this.form.user_token = params.split("&email=")[0] 
        this.form.email = params.split("&email=")[1];
        // console.log(this.form);
        if (this.form.password) {
          const data = { ...this.form };
          this.$http.resetPaymentPassword(data).then((res) => {
            if (res.status == 200) {
              this.$message.success("Set successfully");
            }
          });
        } else {
          this.$message.info(this.$t('Set.9a5@please_fill_in_the_p'));
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .resetPassword {
    .title {
      font-size: 28px;
      font-weight: 400;
      color: #3f414d;
      margin-bottom: 83px;
    }
  }
</style>